<template>
	<section class="region-map">
		<h3 class="region-map__headline">
			{{ destinationName }} Karte
		</h3>
		<MapPreviewAndModal
			v-if="hotelRequestBody"
			:lat="lat"
			:lng="lng"
			:title="destinationName"
			:maps-key="'AIzaSyDBc2xkj5NW4H9Llr0x4XsQ6zPkO5hDa7I'"
			:static-maps="staticMaps"
			:marker="false"
			:zoom="zoom"
			:custom-marker-data="customMarkerData"
			:ga-event-label="'Urlaubsseite'"
			:northwest="northwest"
			:southeast="southeast"
			:hotel-request-body="hotelRequestBody!"
			open-fullscreen="true"
			class="region-map__map"
		/>
	</section>
</template>

<script lang="ts" setup>
import MapPreviewAndModal from '@lmt-rpb/MapPreviewAndModal/MapPreviewAndModal.vue';
import { StaticMaps, MarkerData } from '@/interfaces/google-maps';
import { onMounted, ref } from 'vue';
import { useStore } from '@/components/common/store';
import { ApiDataTypes } from '@/components/common/types';

interface Props {
	destinationName: string,
	staticMaps: StaticMaps,
	customMarkerData?: Array<MarkerData>,
	lat: number,
	lng: number,
	zoom: number,
	northwest: {lat: number, lng: number} | null,
	southeast: {lat: number, lng: number} | null,
}

const hotelRequestBody = ref<ApiDataTypes>();

const store = useStore();

const apiData = (override?: any, exclude?: string[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

function initDefaultSearchSettings() {
	const hotelOnly = store.state.searchMask.onlyHotel;
	const exclude = hotelOnly ? ['DepartureAirport', 'DirectFlight', 'Transfer'] : [];
	exclude.push('DefiniteStartDate', 'TourOperatorCodes', 'HotelIffCode');
	const travelType = hotelOnly ? 'Accommodation' : 'Package';
	hotelRequestBody.value = apiData({ travelType }, exclude);
}

onMounted(() => {
	initDefaultSearchSettings();
});

withDefaults(defineProps<Props>(), {
	customMarkerData: () => []
});

</script>

<style lang="scss" scoped>
	.region-map {
		width: 46rem;
		min-width: 25rem;

		&__headline {
			text-align: left;
			font-size: $font-large;
			font-weight: $bold-default;
			padding: 2rem 2rem 1rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		&__map {
			padding: 0;
			height: 71%;
		}
	}
</style>
