/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import { throttle } from '@utils/utils';
import { documentReady } from '../../global/helper';
import { HeaderMenu, initModal } from './headerMenu';
import { EventBus } from '@global-js/event-bus';

function addHeaderScrollAnimation() {
	let lastScrollPos = window.scrollY;
	document.addEventListener('scroll', throttle(() => {
		const yPosition = window.scrollY;
		if (yPosition > 200 && (yPosition - lastScrollPos > 0)) {
			if (
				!document.body.classList.contains('header-main-menu-open')
				&& !document.body.classList.contains('header-main-favorite-open')
				&& !document.body.classList.contains('search-open')
			) {
				document.body.classList.add('page-header--hidden');
				lastScrollPos = window.scrollY;
			}
		} else if (yPosition - lastScrollPos < -100) {
			document.body.classList.remove('page-header--hidden');
			lastScrollPos = window.scrollY;
		}
	}, 150));
}

addHeaderScrollAnimation();

/**
 * since typo caches pages per page, and ignores the query params of the url, this is the easiest way to differentiate between /suche and /suche?ibe=hotel
 */
function fixSearchPageActiveState() {
	if (document.URL.includes('/suche')) {
		const hotelLink = document.querySelectorAll('.header-submenu__entry a[href="/suche/?ibe=hotel"]');
		const packageLink = document.querySelectorAll('.header-submenu__entry a[href="/suche/"]');

		const setActive = (e: Element) => e.parentElement?.classList.add('header-submenu__entry--active');
		const setInActive = (e: Element) => e.parentElement?.classList.remove('header-submenu__entry--active');

		if (document.URL.includes('ibe=hotel')) {
			hotelLink?.forEach(setActive);
			packageLink?.forEach(setInActive);
		} else {
			packageLink?.forEach(setActive);
			hotelLink?.forEach(setInActive);
		}
	}
}

const initHeaderMenu = () => {
	const hambugerbtn = document.querySelector('.header-iconbar__entry-menu');
	const menuContainer = document.querySelector('.header-main-menu') as HTMLElement;
	if (hambugerbtn && menuContainer) {
		const headerMenu = new HeaderMenu(hambugerbtn, menuContainer);
		headerMenu.addClickListener();
		initModal();
		fixSearchPageActiveState();
	}
};

documentReady(initHeaderMenu);
