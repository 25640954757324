import { EventBus } from "@global-js/event-bus";
import { convertToDateIfNumber } from '@/js/utils/dateUtils';

/**
 * This function takes the destination and dateFrom/dateTo values from the desktop search (via data-attributes)
 * and sets them on the pages where the mobileSearch has no values (only editorial pages)
 * the data-attributes are set in quicksearch-v2-tmpl on the <section> tag of the else from the if(is_editorial)
 */
document.addEventListener('DOMContentLoaded', function() {
	// searchContainer
	const searchContainer = document.getElementById('search-form-container');
	// data-attributes from the searchContainer (destination & date)

	if (searchContainer) {
		const destination = searchContainer.getAttribute('data-destination') !== 'null' && searchContainer.getAttribute('data-destination') ? JSON.parse(decodeURIComponent(searchContainer.getAttribute('data-destination'))).label : '-';

		// mobileSearch variables
		const mobileSearch = document.getElementsByClassName('rpb_headerbild__search-button') ? document.getElementsByClassName('rpb_headerbild__search-button')[0] : '';
		const mobileSearchButtonName = mobileSearch && mobileSearch.getElementsByClassName('rpb_headerbild__search-button-name') ? mobileSearch.getElementsByClassName('rpb_headerbild__search-button-name')[0] : '';
		const mobileSearchButtonDate = mobileSearch && mobileSearch.getElementsByClassName('rpb_headerbild__search-button-date') ? mobileSearch.getElementsByClassName('rpb_headerbild__search-button-date')[0] : '';

		// Search Mask Data
		const searchMaskData = document.getElementById('search-mask-data');
		const dataDestination = searchMaskData ? searchMaskData.getAttribute('data-destination') : 'null';
		const searchMaskDataDestination = dataDestination !== 'null' && dataDestination !== null ? JSON.parse(decodeURIComponent(dataDestination)).label : '-';

		// Date string
		const ddate = getDateOfAttribute(searchMaskData.getAttribute('data-ddate'));
		const rdate = getDateOfAttribute(searchMaskData.getAttribute('data-rdate'));
		const dateString = getFromTo(ddate, rdate);

		// check if these data-attributes exist
		if (mobileSearch && dateString) {
		// set destination and date
			mobileSearchButtonName.textContent = mobileSearchButtonName.textContent || (destination !== '-' ? destination : searchMaskDataDestination);
			mobileSearchButtonDate.textContent = dateString;
			// add clickEvent to mobileSearchButton to trigger logic of navbarSearchButton

			if (mobileSearch) {
				mobileSearch.addEventListener('click', function() {
					EventBus.$emit('ThemePageSearch:openSearch');
				});
			}
		}
	}
});

function getFromTo(ddate, rdate) {
	if (!ddate || !rdate) return '';
	return getMonthAndDay(ddate) + ' - ' + getMonthAndDay(rdate);
}

function getMonthAndDay(date) {
	if (!date) return '';
	const options = { day: 'numeric', month: 'long' };
	return date.toLocaleString('de-DE', options);
}

function getDateOfAttribute(dateAttr) {
	if (!dateAttr) return '';
	return new Date(convertToDateIfNumber(dateAttr));
}
